import { BridgeConsumeType, type Bridge } from './bridge';
import { resolveHost, type Config } from './config';
import type { JsonNode, JsonTextNode } from './jsonNode';
import type { Link } from './types';

const DEFAULT_RESPONSE_TIMEOUT = 10000;
const RESPONSE_TIMEOUT_REASON = 'Response timeout';

export enum QueryInputMethod {
    text = 'text',
    voice = 'voice',
    selectAction = 'select_action'
}

export interface QueryRequest {
    query: string;
    user_id: string;
    last_turn?: string;
    conv?: string;
    integration_key?: string;
    input_method: QueryInputMethod;
}

export interface EventRequest {
    user_id: string;
    conv?: string;
    integration_key?: string;
    headers?: Record<string, string>;
    params?: Record<string, string>;
}

export interface EventResponse {
    conv?: string;
    custom: Record<string, string>;
}

export interface QueryResponse {
    alt_responses: QueryAltResponse[];
    response: QuerySubResponse;
    turn: string;
    conv: string;
    back?: string;
    query?: string;
}

export interface QueryErrorResponse {
    response: QuerySubResponse;
    turn: string;
    conv: string;
}

export interface QueryAltResponse {
    confidence: string;
    content_id: string;
    oos: boolean;
    response_text: string;
    source: QuerySubResponseSource;
    subject: string;
    type: string;
    text: string | null;
    best_segment?: QueryBestSegment | null;
}

export interface QueryBestSegment {
    prefix: string;
    segment: string;
    suffix: string;
}

export interface QuerySubResponse {
    type: string;
    subtype: string;
    subject: string;
    visual: ResponseNode;
    expanded_visual: ResponseNode;
    aural?: QuerySubResponseAural;
    tags: QuerySubResponseTag[];
    source?: QuerySubResponseSource;
    transactional?: QuerySubResponseTransactional;
    content_id: string | null;
    hierarchy: number | null;
    response_id: number;
    trimmed_visual: string;
    confidence: string;
    best_segment?: QueryBestSegment | null;
}

export interface QuerySubResponseTransactional {
    node_name: string;
    intent: string;
    new_slots: QuerySubResponseTransactionalSlots[];
    turn_data: QuerySubResponseTransactionalTurnData[];
}

export interface QuerySubResponseTransactionalSlots {
    slot: string;
    id: string;
    type: string;
    score: string;
    start: number;
    end: number;
    tokens: string;
    extraction: QuerySubResponseTransactionalSlotsExtraction;
}

export interface QuerySubResponseTransactionalSlotsExtraction {
    context: string;
    question: string;
    context_start: number;
    context_end: number;
}

export interface QuerySubResponseTransactionalTurnData {
    key: string;
    value: string;
}

export interface QuerySubResponseAural {
    type: 'text' | 'dynamic-tts';
    path: string;
    text: string;
}

export interface QuerySubResponseTag {
    group: string;
    value: string;
}

export interface QuerySubResponseSource {
    correlation_id?: string | null;
    location?: string | null;
    name?: string | null;
}

export enum ResponseNodeType {
    html = 'html',
    list = 'list',
    domReference = 'dom_reference',
    json = 'json',
    terms = 'terms'
}

export type ResponseNode =
    | HtmlResponseNode
    | JsonResponseNode
    | ListResponseNode
    | DomReferenceResponseNode
    | TermsResponseNode;

export class HtmlResponseNode {
    type: ResponseNodeType.html;
    value: string;

    constructor(value: string) {
        this.value = value;
        this.type = ResponseNodeType.html;
    }
}

export class JsonResponseNode {
    type: ResponseNodeType.json;
    payload: JsonNode[];

    constructor(value: string) {
        const response: JsonTextNode = {
            type: 'hierarchy_text',
            value
        };
        this.payload = [response];
        this.type = ResponseNodeType.json;
    }
}

export class ListResponseNode {
    type: ResponseNodeType.list;
    children: Array<ResponseNode>;
}

export class DomReferenceResponseNode {
    type: ResponseNodeType.domReference;
    url: string;
    id: string;
}

export class TermsResponseNode {
    type: ResponseNodeType.terms;
    links: Link[];
    texts: string[];
}

export interface QueryErrorResponse {
    errors: PersistError[];
}

export interface ToucanErrorResponse {
    trace_id: string;
    status: number;
    title: string;
    detail: string;
    timestamp: string;
    service: string;
    method: 'get' | 'post' | 'put' | 'patch';
    path: string;
    template_path: string;
    traceback: string[];
}

export interface PersistError {
    status: string;
    title: string;
    detail: string;
    timestamp: string;
    path: string;
}

const checkQueryError = async (result: Response) => {
    const jsonRes = (await result.json()) as QueryResponse | QueryErrorResponse;
    if (!result.ok) {
        tryParseQueryError(result, jsonRes as QueryErrorResponse);
    }
    return jsonRes as QueryResponse;
};

const tryParseQueryError = (result: Response, response: QueryErrorResponse | ToucanErrorResponse) => {
    if (result.status === 401) {
        throw new Error('This application is unauthorized. Please validate the deployment is valid for this domain.');
    }
    const queryError = response as QueryErrorResponse;
    if (queryError.errors) {
        throw new Error(queryError.errors[0].detail);
    }
    const toucanError = response as ToucanErrorResponse;
    if (toucanError.detail) {
        throw new Error(toucanError.detail);
    }
    throw new Error('We encountered a problem and were unable to process your request.');
};

const handleQueryError = (e: any, bridge: Bridge, abortController?: AbortController) => {
    const isTimeout = abortController?.signal.reason === RESPONSE_TIMEOUT_REASON;
    if (e instanceof DOMException && e.code === DOMException.ABORT_ERR && !isTimeout) {
        throw e; // Rethrow cancellation error to suppress it upstream
    }

    const errorMessage = isTimeout ? RESPONSE_TIMEOUT_REASON : (e as Error).message;
    bridge._sendConsumable(BridgeConsumeType.error, { message: errorMessage });
    throw new Error(errorMessage);
};

const throttleRequest = (abortController: AbortController, responseTimeout?: number) => {
    const requestScopedAbortController = new AbortController();
    const signal = requestScopedAbortController.signal;
    signal.addEventListener('abort', () => {
        // propagate to the passed-in controller
        abortController.abort();
    });

    const timeoutId = setTimeout(
        () => requestScopedAbortController.abort(RESPONSE_TIMEOUT_REASON),
        responseTimeout ?? DEFAULT_RESPONSE_TIMEOUT
    );
    return { timeoutId, controller: requestScopedAbortController };
};

const buildURL = (config: Config, endpoint: string): string => [resolveHost(config.host), endpoint].join('');

export const makeQuery = async (
    request: QueryRequest,
    config: Config,
    bridge: Bridge,
    abortController: AbortController
): Promise<QueryResponse> => {
    const { timeoutId, controller: requestScopedAbortController } = throttleRequest(
        abortController,
        config.responseTimeout
    );
    const extraData = bridge._receiveExtraData();
    const params = {
        method: 'POST',
        headers: {
            ...(config.extraHeaders ?? {}),
            ...(extraData.headers ?? {}),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: request.query,
            user_id: request.user_id,
            conv: request.conv,
            input_method: request.input_method,
            integration_key: request.integration_key,
            deployment_key: config.appKey,
            custom: {
                ...(config.extraParams ?? {}),
                ...(extraData.params ?? {})
            },
            location: window.location.href
        }),
        // pass the original signal so it can be aborted upstream
        signal: abortController.signal
    };
    try {
        const url = buildURL(config, '/api/deploy/v2/query');
        const result = await fetch(url, params);
        clearTimeout(timeoutId);
        return await checkQueryError(result);
    } catch (e) {
        clearTimeout(timeoutId);
        handleQueryError(e, bridge, requestScopedAbortController);
    }
};

export const sendEvent = async (
    request: EventRequest,
    config: Config,
    bridge: Bridge,
    abortController: AbortController
): Promise<EventResponse> => {
    const { timeoutId, controller: requestScopedAbortController } = throttleRequest(
        abortController,
        config.responseTimeout
    );
    const extraData = bridge._receiveExtraData();
    const params = {
        method: 'POST',
        headers: {
            ...(config.extraHeaders ?? {}),
            ...(extraData.headers ?? {}),
            ...(request.headers ?? {}),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            deployment_key: config.appKey,
            integration_key: request.integration_key,
            user_id: request.user_id,
            conv: request.conv,
            location: window.location.href,
            custom: {
                ...(config.extraParams ?? {}),
                ...(extraData.params ?? {}),
                ...(request.params ?? {})
            }
        }),
        // pass the original signal so it can be aborted upstream
        signal: abortController.signal
    };
    try {
        const url = buildURL(config, '/api/deploy/v1/event');
        const result = await fetch(url, params);
        clearTimeout(timeoutId);
        const jsonRes = (await result.json()) as EventResponse | ToucanErrorResponse;
        if (!result.ok) {
            tryParseQueryError(result, jsonRes as ToucanErrorResponse);
        }
        return jsonRes as EventResponse;
    } catch (e) {
        clearTimeout(timeoutId);
        handleQueryError(e, bridge, requestScopedAbortController);
    }
};

export const tts = async (config: Config, bridge: Bridge, text: string, dynamic = false) => {
    const extraData = bridge._receiveExtraData();
    const params = {
        method: 'POST',
        headers: {
            ...(config.extraHeaders ?? {}),
            ...(extraData.headers ?? {}),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            text: text,
            gender: config.ttsGender,
            voice: config.ttsVoice,
            pipeline_id: config.appKey,
            integration_key: config.integrationKey,
            dynamic
        })
    };
    try {
        const url = buildURL(config, '/api/deploy/v2/dictate');
        const result = await fetch(url, params);
        if (!result.ok) {
            const errorRes = (await result.json()) as QueryErrorResponse;
            tryParseQueryError(result, errorRes);
        }
        const blob = await result.blob();
        return URL.createObjectURL(blob);
    } catch (e) {
        handleQueryError(e, bridge);
    }
};
