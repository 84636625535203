import { v4 } from 'uuid';
import { resolveHost, type Config } from './config';
import type { JsonNode } from './jsonNode';
import {
    JsonResponseNode,
    ResponseNodeType,
    TermsResponseNode,
    type QueryRequest,
    type QueryResponse,
    type ResponseNode
} from './query';
import type { Link } from './types';

const LOCAL_IMAGE_PATTERN = new RegExp(`src='/image/i/`, 'g');

export enum BubbleSource {
    user = 'user',
    system = 'system',
    error = 'error'
}

export enum RequestType {
    none = 'none',
    query = 'query'
}

export const getLastBubble = (bubbles: Bubble[]): Bubble | null => {
    if (!bubbles.length) {
        return null;
    }
    return bubbles[0];
};

export const getLastSystemBubble = (bubbles: Bubble[]): Bubble | null => {
    const bubble = bubbles.find((bubble) => bubble.source === BubbleSource.system);
    if (!bubble) {
        return null;
    }
    return bubble;
};

export class Bubble {
    value: ResponseNode;
    expanded_value: ResponseNode;
    is_expanded: boolean;
    source: BubbleSource;
    id: string;
    request: QueryRequest | null;
    request_type: RequestType;
    response: QueryResponse;

    constructor(
        value: ResponseNode,
        source: BubbleSource,
        request: QueryRequest = null,
        response: QueryResponse = null
    ) {
        this.value = value;
        this.source = source;
        this.id = v4();
        this.request = request;
        this.response = response;
        this.expanded_value = response?.response?.expanded_visual;
        this.is_expanded = false;
        this.request_type = RequestType.query;

        // Use cutoff element to rework regular and expanded response values
        if (
            this.value.type === ResponseNodeType.json &&
            this.value.payload.some((e) => e.type === 'hierarchy_cutoff')
        ) {
            this.expanded_value = new JsonResponseNode('');
            this.expanded_value.payload = this.value.payload;
            this.value.payload = [];
            for (const element of this.expanded_value.payload) {
                if (element.type === 'hierarchy_cutoff') {
                    break;
                }
                this.value.payload.push(element);
            }
        }
    }

    public static Create = (message: string | JsonNode[], source: BubbleSource): Bubble => {
        if (typeof message === 'string') {
            return new Bubble(new JsonResponseNode(message), source);
        }
        return new Bubble(
            {
                type: ResponseNodeType.json,
                payload: message
            } as JsonResponseNode,
            source
        );
    };

    public static CreateTerms = (texts: string[], links: Link[]) => {
        const termsResponseNode: TermsResponseNode = {
            type: ResponseNodeType.terms,
            texts,
            links
        };
        return new Bubble(termsResponseNode, BubbleSource.system);
    };

    public static CreateWelcome = (message: string | JsonNode[]): Bubble => {
        return this.Create(message, BubbleSource.system);
    };

    public static CreateError = (message: string | JsonNode[]): Bubble => {
        return this.Create(message, BubbleSource.error);
    };

    public static CreateUnknown = (
        request: QueryRequest,
        response: QueryResponse,
        source: BubbleSource,
        config: Config
    ): Bubble => {
        const visual = response.response.visual;
        if (visual.type === ResponseNodeType.html) {
            // This is a hack to fix any relative image references
            visual.value = visual.value.replace(
                new RegExp(LOCAL_IMAGE_PATTERN, 'g'),
                `src='${resolveHost(config.host)}/image/i/`
            );
        }

        return new Bubble(visual, source, request, response);
    };
}
