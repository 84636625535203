<script lang="ts">
    import DropDown from './DropDown.svelte';

    import { createEventDispatcher, getContext, onMount } from 'svelte';
    import { fade } from 'svelte/transition';

    import { resolveHost, type Config } from '../config';

    import type { Writable } from 'svelte/store';
    import type { DropdownOption } from '../dropdown';
    import { ChatHistorySymbol, type ChatHistory } from '../session';

    import { BridgeConsumeType, type Bridge } from '../bridge';
    import { QueryInputMethod } from '../query';
    import { SpeechRecorder, Status } from '../recorder';
    import Wave from './Wave.svelte';

    let recorder: SpeechRecorder | undefined = undefined;
    let queryTextInput: HTMLInputElement;

    export let queryText: string;
    export let config: Config;
    export let bridge: Bridge;
    export let loading: boolean;
    export let disabled: boolean;
    export let dropdownOptions: DropdownOption[];
    export let dropdownDirection: 'up' | 'down';
    const micColors = {
        [Status.off]: 'white',
        [Status.on]: 'red',
        [Status.handshake]: 'orange',
        [Status.failed]: 'gray'
    };
    let micStatus = Status.off;
    let placeholder = '';
    $: micColor = micColors[micStatus];
    $: inputDisabled = micStatus === Status.on || micStatus === Status.handshake || loading || disabled;
    $: if (micStatus === Status.on) {
        placeholder = "I'm listening...";
    } else {
        placeholder = config.controlBar.placeholderText;
    }

    const chatHistory: Writable<ChatHistory> = getContext(ChatHistorySymbol);

    const dispatch = createEventDispatcher();

    const onSubmit = () => dispatchQuery();
    const dispatchQuery = (voice = false) => {
        if (!queryText || (inputDisabled && !voice)) {
            return;
        }
        dispatch('query', {
            text: queryText,
            type: voice ? QueryInputMethod.voice : QueryInputMethod.text
        });
        queryText = '';
    };
    const onInput = () => {
        dispatchInput();
    };
    const dispatchInput = () => {
        dispatch('input', { text: queryText });
    };

    const setTranscript = (newValue: string) => {
        queryText = newValue;
        dispatchInput();
        queryTextInput.scrollLeft = Number.MAX_SAFE_INTEGER;
    };

    const onMic = async () => {
        if (recorder?.status !== Status.off) {
            await recorder?.stop();
        } else {
            dispatch('pause');
            setTranscript('');
            const extraData = bridge._receiveExtraData();
            await recorder.start({ ...extraData.headers, origin: window.location.origin });
        }
    };

    const setStatus = (status: Status) => {
        micStatus = status;
        if (status === Status.failed) {
            $chatHistory.voiceDisabled = true;
        }
    };

    const buildAsrUrl = (config: Config, type: string): string => {
        return [resolveHost(config.host, 'wss:'), '/api/deploy/v2/', type].join('');
    };

    onMount(() => {
        recorder = new SpeechRecorder(
            buildAsrUrl(config, 'transcribe'),
            config.appKey,
            config.integrationKey ?? null,
            config.extraHeaders,
            config.locale
        );
        recorder.TranscriptReceived.on((data) => {
            setTranscript(data);
        });
        recorder.ErrorReceived.on((data) => {
            dispatch('error', { text: data });
        });
        recorder.StatusChanged.on((status) => {
            setStatus(status);
            bridge._sendConsumable(BridgeConsumeType.asr, {
                status,
                user_id: $chatHistory.userId,
                conv_id: $chatHistory.convId
            });
        });
        recorder.TranscriptionCompleted.on((data) => {
            setTranscript(data);
            dispatchQuery(true);
        });
        setStatus(recorder.status);
    });
</script>

<template>
    <div class="knowbl--control-bar-container-parent" class:knowbl--control-bar-container-parent-disabled={disabled}>
        <div class="knowbl--control-bar-container" style="--knowbl-var-control-bar-two-mic-color: {micColor};">
            {#if micStatus === Status.on}
                <div class="knowbl--control-bar-wave-parent">
                    <div class="knowbl--control-bar-wave">
                        <Wave bind:recorder />
                    </div>
                </div>
            {/if}
            <form class="knowbl--control-bar-text-content-form" on:submit|preventDefault={onSubmit}>
                <button
                    disabled={inputDisabled || !queryText}
                    type="submit"
                    aria-label="Submit search"
                    class="knowbl--control-bar-text-content-submit"
                >
                    <svg
                        class="knowbl--control-bar-search-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="1.5"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                        />
                    </svg>
                </button>
                <input
                    {placeholder}
                    bind:value={queryText}
                    bind:this={queryTextInput}
                    on:input={onInput}
                    readonly={inputDisabled}
                    type="text"
                    class="knowbl--control-bar-text-input"
                    autocomplete="off"
                    data-lpignore="true"
                    spellcheck="false"
                />
            </form>
            {#if loading}
                <div class="knowbl--control-bar-loader" transition:fade|local={{ duration: 250 }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        role="img"
                        focusable="false"
                        aria-hidden="true"
                        class="knowbl--control-bar-loader-svg"
                    >
                        <circle
                            class="knowbl--control-bar-loader-svg-circle"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            fill="transparent"
                            stroke-width="4"
                        />
                        <path
                            class="knowbl--control-bar-loader-svg-path"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                    </svg>
                </div>
            {/if}
            <DropDown options={dropdownOptions} disabled={inputDisabled} direction={dropdownDirection} />
            <button
                disabled={inputDisabled || micStatus === Status.failed}
                type="button"
                class="knowbl--control-bar-voice-mic-button"
                aria-label="Toggle microphone"
                on:click={onMic}
            >
                <svg class="knowbl--control-bar-voice-svg" viewBox="0 0 51 52">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-1002.000000, -821.000000)" fill="currentColor" fill-rule="nonzero">
                            <path
                                d="M1050.875,847 C1052.04861,847 1053,847.97005 1053,849.166667 C1052.98501,862.120613 1042.84761,872.655844 1030.21877,872.991754 L1029.625,873 L1025.375,873 C1012.47165,872.984479 1002.01522,862.323019 1002,849.166667 C1002,847.97005 1002.95139,847 1004.125,847 C1005.29861,847 1006.25,847.97005 1006.25,849.166667 C1006.26266,859.745187 1014.52543,868.352143 1024.83076,868.658254 L1025.375,868.666667 L1029.625,868.666667 C1040.18259,868.654726 1048.73829,859.931269 1048.75,849.166667 C1048.75,847.97005 1049.70139,847 1050.875,847 Z M1027.5,821 C1036.98926,821 1044.71449,828.423222 1044.99227,837.683676 L1045,838.2 L1045,846.8 C1044.98915,856.294877 1037.16049,863.989336 1027.5,864 C1018.01516,863.98953 1010.29613,856.572135 1010.00831,847.316081 L1010,846.8 L1010,838.2 C1010,828.700702 1017.83502,821 1027.5,821 Z M1028,825 C1021.82581,825.011573 1016.53427,829.396724 1015.28516,835.456334 L1015.195,835.938154 L1021.5,835.938154 C1022.69662,835.938154 1023.66667,836.917589 1023.66667,838.125784 C1023.66667,839.253433 1022.82165,840.181807 1021.73608,840.300578 L1021.5,840.313415 L1015,840.313415 L1015,844.688677 L1021.5,844.688677 C1022.69662,844.688677 1023.66667,845.668112 1023.66667,846.876307 C1023.66667,848.003956 1022.82165,848.93233 1021.73608,849.051101 L1021.5,849.063938 L1015.195,849.063938 C1016.24655,855.376884 1021.65974,860 1028,860 C1034.17769,860 1039.47524,855.610927 1040.71557,849.546167 L1040.805,849.063938 L1034.5,849.063938 C1033.30338,849.063938 1032.33333,848.084503 1032.33333,846.876307 C1032.33333,845.748659 1033.17835,844.820285 1034.26392,844.701513 L1034.5,844.688677 L1041,844.688677 L1041,840.313415 L1034.5,840.313415 C1033.30338,840.313415 1032.33333,839.33398 1032.33333,838.125784 C1032.33333,836.998136 1033.17835,836.069762 1034.26392,835.95099 L1034.5,835.938154 L1040.805,835.938154 C1039.74362,829.630551 1034.33667,825.011878 1028,825 Z"
                            />
                        </g>
                    </g>
                </svg>
            </button>
        </div>
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-container-parent) {
        position: relative;
        min-height: 50px;
        max-height: 50px;
        margin: 0.5em;

        box-sizing: border-box;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-container-parent-disabled) {
        opacity: 60%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-container-parent-disabled) :global(*) {
        cursor: not-allowed;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-container) {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        border: 1.5px solid var(--knowbl-var-window-accent-color);
        background-color: var(--knowbl-var-window-accent-light-color);
        border-radius: 50px;
        box-sizing: border-box;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-loader) {
        flex-shrink: 0;
        height: 100%;
        width: 20px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        color: var(--knowbl-var-window-accent-color);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-loader-svg) {
        color: var(--knowbl-var-window-accent-color);
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-loader-svg-circle) {
        width: 25px;
        height: 25px;
        opacity: 0.75;
        color: var(--knowbl-var-window-accent-color);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-loader-svg-path) {
        width: 25px;
        height: 25px;
        opacity: 0.25;
        color: white;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-wave-parent) {
        height: 100%;
        width: 100%;
        position: absolute;
        box-sizing: border-box;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-wave) {
        height: 100%;
        width: 100%;
        opacity: 0.6;
        overflow: hidden;
        border-radius: 50px;
        position: relative;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-text-content-form) {
        flex: 1 1;
        display: flex;
        min-width: 0;
        position: relative;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-text-input) {
        min-width: 20px;
        font-size: 16px;
        flex: 1 1;
        padding: 0 0.5em;

        color: white;
        font-family: var(--knowbl-var-text-font-family);

        display: inline-block;
        overflow: hidden;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-text-content-submit) {
        flex-shrink: 0;
        color: white;
        width: 25px;
        display: flex;
        cursor: pointer;
        padding-left: 0.75em;
        padding-right: 0.25em;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-text-content-submit:disabled) {
        cursor: not-allowed;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-text-input::placeholder) {
        color: var(--knowbl-var-color-placeholder-text);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-text-input:placeholder-shown) {
        text-overflow: ellipsis;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-voice-mic-button) {
        height: 40px;
        width: 40px;
        margin: auto 0.2em;
        flex-shrink: 0;

        color: black;

        display: inline-block;
        box-sizing: border-box;
        cursor: pointer;
        background-color: var(--knowbl-var-window-accent-light-color);
        border-radius: 100%;
        position: relative;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-voice-mic-button:hover:not([disabled])) {
        background-color: var(--knowbl-var-window-accent-color);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-voice-mic-button:disabled) {
        cursor: not-allowed;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-search-svg) {
        height: 50%;
        display: block;
        margin: auto 0;
        color: white;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--control-bar-voice-svg) {
        height: 35%;
        width: 35%;
        display: block;
        margin-left: 0.8em;
        color: var(--knowbl-var-control-bar-two-mic-color);
    }
</style>
