<script lang="ts">
    import { createEventDispatcher, getContext } from 'svelte';
    import { fade } from 'svelte/transition';

    import { Bubble, BubbleSource } from '../bubble';

    import type { Config } from '../config';

    import type { ActionEvent, ErrorEvent, LinkEvent, QueryEvent } from '../event';
    import {
        QueryInputMethod,
        ResponseNodeType,
        makeQuery,
        tts,
        type QueryRequest,
        type QueryResponse
    } from '../query';

    import { ChatHistorySymbol, type ChatHistory } from '../session';
    import type { Sound } from '../sound';

    import type { Writable } from 'svelte/store';
    import { ActionType } from '../action';
    import { BridgeConsumeType, type Bridge } from '../bridge';
    import type { DropdownOption } from '../dropdown';
    import ControlBar from './ControlBar.svelte';
    import Footer from './Footer.svelte';
    import SpeechBubbleList from './SpeechBubbleList.svelte';

    export let bubbles: Array<Bubble>;
    export let sound: Sound;
    export let config: Config;
    export let bridge: Bridge;

    export let loading: boolean;
    export let isChildOfBody: boolean;
    export let dropdownOptions: DropdownOption[];
    export let abortController: AbortController;
    export let lastInput: string;

    const disabled = false;
    const dispatch = createEventDispatcher();
    const debounce = 400;

    const chatHistory: Writable<ChatHistory> = getContext(ChatHistorySymbol);

    let responseBubble: Bubble | null = $chatHistory.lastSystemBubble;

    const handleQuery = async (
        request: QueryRequest,
        responder: Promise<QueryResponse>,
        options?: { clearOnError?: boolean; forceMute?: boolean }
    ) => {
        loading = true;
        sound.pause();
        responseBubble = null;
        const lastConvId = $chatHistory.convId;

        const start = Date.now();
        try {
            const response: QueryResponse = await responder;
            const delta = Date.now() - start;

            bridge._sendConsumable(BridgeConsumeType.response, response);
            if (lastConvId !== response.conv) {
                bridge._sendConsumable(BridgeConsumeType.newConversation, {
                    last_conv_id: lastConvId,
                    user_id: $chatHistory.userId,
                    conv_id: response.conv
                });
            }

            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(async function () {
                responseBubble = Bubble.CreateUnknown(request, response, BubbleSource.system, config);
                dispatch('bubble', {
                    bubble: responseBubble
                });

                $chatHistory.pipelineConvId = response.conv;
                $chatHistory.lastTurnId = response.turn;

                if (!$chatHistory.prefersMute && !options?.forceMute) {
                    let text = response.response?.aural?.text;
                    let dynamic =
                        response.response?.subtype === 'transactional' ||
                        response.response?.subtype === 'scenario' ||
                        response.response?.type === 'scenario';

                    // TODO(sean): remove dynamic-tts type after v1.7.0
                    if (
                        response.response?.aural?.type === 'dynamic-tts' &&
                        response.response?.visual?.type === ResponseNodeType.json
                    ) {
                        const visual_payload = response.response.visual.payload;

                        let message = '';
                        for (const messageData of visual_payload) {
                            if (messageData.type === 'hierarchy_paragraph') {
                                message = messageData.value;
                                break;
                            }
                        }

                        text = message;
                        dynamic = true;
                    }

                    if (text) {
                        bridge._sendConsumable(BridgeConsumeType.tts, {
                            text,
                            user_id: $chatHistory.userId,
                            turn_id: $chatHistory.lastTurnId,
                            conv_id: $chatHistory.convId
                        });
                        try {
                            sound.play(await tts(config, bridge, text, dynamic));
                        } catch (error) {
                            console.error('Unable to play sound: ', error);
                            bridge._sendConsumable(BridgeConsumeType.error, {
                                message: `TTS failed: ${(error as Error).message ?? 'unknown reason'}`
                            });
                            $chatHistory.prefersMute = true;
                        }
                    }
                }

                loading = false;
            }, debounce - delta);
        } catch (e) {
            if (options?.clearOnError) {
                dispatch('clear');
                loading = false;
                return;
            }
            if (e instanceof DOMException) {
                // This should be limited to fetch cancelations
                loading = false;
                return;
            }
            const delta = Date.now() - start;
            responseBubble = Bubble.CreateError(config.bubbles.errorMessage);
            setTimeout(function () {
                dispatch('bubble', {
                    bubble: responseBubble
                });
                loading = false;
            }, debounce - delta);
        }
    };

    const onQueryEvent = async (event: CustomEvent<QueryEvent>) => {
        await onQuery(event.detail.text, event.detail.type);
    };
    const onQuery = async (text: string, inputMethod: QueryInputMethod = QueryInputMethod.text) => {
        const queryText = text;
        dispatch('bubble', {
            bubble: Bubble.Create(text, BubbleSource.user)
        });

        const request: QueryRequest = {
            query: queryText,
            user_id: $chatHistory.userId,
            input_method: inputMethod
        };
        if ($chatHistory.pipelineConvId) {
            request.conv = $chatHistory.pipelineConvId;
        } else if ($chatHistory.lastTurnId) {
            request.last_turn = $chatHistory.lastTurnId;
        }
        if (config.integrationKey) {
            request.integration_key = config.integrationKey;
        }

        bridge._sendConsumable(BridgeConsumeType.query, {
            text: queryText,
            user_id: $chatHistory.userId
        });
        await handleQuery(request, makeQuery(request, config, bridge, abortController));
    };

    const onError = (event: CustomEvent<ErrorEvent>) => {
        // currently this is only used for errors related to ASR
        bridge._sendConsumable(BridgeConsumeType.error, { message: event.detail.text });
        responseBubble = Bubble.CreateError(config.bubbles.errorMessage);
        dispatch('bubble', {
            bubble: responseBubble
        });
    };

    const onAction = (event: CustomEvent<ActionEvent>) => {
        const action = event.detail.action;
        switch (action.type) {
            case ActionType.event:
                bridge._sendConsumable(BridgeConsumeType.action, {
                    id: action.event,
                    turn_id: $chatHistory.lastTurnId,
                    conv_id: $chatHistory.convId,
                    user_id: $chatHistory.userId
                });
                break;
            case ActionType.query:
                onQuery(action.utterance, QueryInputMethod.selectAction);
                break;
            case ActionType.terms:
                $chatHistory.consentedToTerms = true;
                responseBubble = Bubble.CreateWelcome(config.bubbles.welcomeMessage);
                dispatch('bubble', {
                    bubble: responseBubble
                });
                break;
            default:
                break;
        }
    };

    const onLinkEvent = (event: CustomEvent<LinkEvent>) => {
        const bubble = event.detail.bubble;
        const baseContentId = bubble?.response?.response.content_id;
        const payload = {
            user_id: bubble?.request?.user_id ?? $chatHistory.userId,
            turn_id: bubble?.response?.turn ?? null,
            conv_id: bubble?.response?.conv ?? null,
            query: bubble.request.query ?? null,
            content_id: event.detail.contentId ?? baseContentId ?? null,
            subject: event.detail.subject ?? bubble?.response?.response.subject ?? null,
            is_alt: event.detail.isAlt ?? false,
            url: event.detail.url.toString()
        };
        bridge._sendConsumable(BridgeConsumeType.link, payload);
    };
</script>

<template>
    <div in:fade|local class="knowbl--main-content">
        <SpeechBubbleList on:action={onAction} bind:bubbles {config} {bridge} {isChildOfBody} on:link={onLinkEvent} />
        <ControlBar
            bind:loading
            {disabled}
            on:pause={sound.pause}
            on:query={onQueryEvent}
            on:error={onError}
            on:input
            {config}
            {bridge}
            {dropdownOptions}
            dropdownDirection="up"
            queryText={lastInput}
        />
        <Footer {config} />
    </div>
</template>

<style>
    :global(#knowbl-chat-widget) :global(.knowbl--main-content) {
        width: 100%;
        flex: 1 0;
        min-height: 0;
        display: flex;
        flex-direction: column;
        padding: 0;
    }
</style>
