import type { BubbleSource } from './bubble';
import type { JsonNode } from './jsonNode';
import type { JSONValue } from './types';

export enum BridgePublishType {
    refresh = 'refresh',
    clear = 'clear',
    reset = 'reset',
    open = 'open',
    close = 'close',
    reinit = 'reinit',
    callout = 'callout',
    event = 'event',
    message = 'message'
}

export enum BridgeConsumeType {
    error = 'error',
    action = 'action',
    query = 'query',
    response = 'response',
    asr = 'asr',
    tts = 'tts',
    open = 'open',
    close = 'close',
    link = 'link',
    newConversation = 'new_conversation',
    event = 'event'
}

export interface Callout {
    text: string;
    millis: number;
}

export interface CustomMessage {
    message: string | JsonNode[];
    source?: BubbleSource;
}

export interface ExternalEvent {
    headers?: Record<string, string>;
    params?: Record<string, string>;
}

export interface ExtraData {
    headers?: Record<string, string>;
    params?: Record<string, string>;
}

export class Bridge {
    _consumeHandler: (type: string, data: JSONValue) => void | undefined;
    _extraDataHandler: () => ExtraData;
    _publishHandler: (type: string, data: JSONValue) => JSONValue;
    _registerPublishHandler(fn: (type: string, data: JSONValue) => JSONValue) {
        this._publishHandler = fn;
    }
    _sendConsumable(type: string, data: any) {
        if (this._consumeHandler !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this._consumeHandler(type, data);
        }
        if (type === BridgeConsumeType.error) {
            // eslint-disable-next-line no-console
            console.error(`Knowbl chat error: ${(data as { message: string }).message}`);
        }
    }
    _receiveExtraData(): ExtraData {
        if (this._extraDataHandler !== undefined) {
            return this._extraDataHandler();
        }
        return {};
    }
    constructor(consumeHandler?: (type: string, data: JSONValue) => void, extraDataHandler?: () => ExtraData) {
        if (consumeHandler) {
            this._consumeHandler = consumeHandler;
        }
        if (extraDataHandler) {
            this._extraDataHandler = extraDataHandler;
        }
    }
    publish(type: string, data?: JSONValue): JSONValue {
        return this._publishHandler(type, data || null);
    }
}
